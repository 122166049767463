import React from 'react';
import { Link } from 'gatsby';

import { link } from './tag-chip.module.scss';

interface ITagChipProps {
    className?: string;
    name: string;
    pathname: string;
}

const TagChip: React.FC<ITagChipProps> = ({ className = '', name, pathname }) => {
    return (
        <Link to={pathname} className={`${className} ${link}`}>
            {name}
        </Link>
    );
};

export default TagChip;
