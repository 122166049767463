// extracted by mini-css-extract-plugin
export var author = "post-module--author--18908";
export var backgroundAnimation = "post-module--backgroundAnimation--1bb46";
export var caption = "post-module--caption--d0815";
export var categories = "post-module--categories--fb7cf";
export var categoryLink = "post-module--category-link--ce4d4";
export var container = "post-module--container--aea08";
export var date = "post-module--date--c7e42";
export var desktopLeadImage = "post-module--desktop-lead-image--eaebd";
export var desktopLeadImageRatio = "post-module--desktop-lead-image-ratio--50a0c";
export var imageHolder = "post-module--image-holder--bea43";
export var lead = "post-module--lead--ad9f5";
export var leadImage = "post-module--lead-image--16305";
export var leadText = "post-module--lead-text--a252b";
export var markdown = "post-module--markdown--64b67";
export var markdownBox = "post-module--markdown-box--36291";
export var mobileLeadImage = "post-module--mobile-lead-image--50893";
export var mobileLeadImageRatio = "post-module--mobile-lead-image-ratio--05009";
export var sectionBox = "post-module--section-box--58304";
export var tags = "post-module--tags--e4f02";
export var title = "post-module--title--a4be9";