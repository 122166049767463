import React from 'react';

import { list } from './tag-chip-list.module.scss';
import { IPostTag } from '../../models/post-tag.model';

import TagChip from '../atoms/tag-chip';

interface ITagChipListProps {
    className?: string;
    tags: IPostTag[];
}

const TagChipList: React.FC<ITagChipListProps> = ({ className = '', tags }) => {
    return (
        <ul className={`${className} ${list}`}>
            {tags.map((tag) => {
                return (
                    <li key={`tag-item-${tag.tagId}`}>
                        <TagChip name={tag.name} pathname={tag.pathname} />
                    </li>
                );
            })}
        </ul>
    );
};

export default TagChipList;
