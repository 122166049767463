import React from 'react';

import { author, avatar, name, bioText } from './author.module.scss';
import { IAuthor } from '../../models/author.model';

import Avatar from '../atoms/avatar';

type IAuthorProps = {
    className?: string;
    alt?: string;
    author: IAuthor;
    NameTag?: React.ElementType;
};

const Author: React.FC<IAuthorProps> = ({
    className = '',
    author: { displayName, description, media },
    NameTag = 'h2',
}) => {
    return (
        <div className={`${className} ${author}`}>
            <Avatar className={avatar} size="medium" media={media} />
            <NameTag className={name}>{displayName}</NameTag>
            <p className={bioText}>{description}</p>
        </div>
    );
};

export default Author;
