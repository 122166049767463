import React from 'react';
import { Link } from 'gatsby';
import { Image } from '@alterpage/gatsby-plugin-image';

import {
    sectionBox,
    container,
    categories,
    categoryLink,
    title,
    date,
    leadImage,
    desktopLeadImage,
    desktopLeadImageRatio,
    mobileLeadImage,
    mobileLeadImageRatio,
    lead,
    leadText,
    markdownBox,
    markdown,
    tags,
    author,
    imageHolder,
    caption as captionClass,
} from './post.module.scss';

import { relations } from '../../config/relations';
import { ISection } from '../../models/section.model';
import { IPost } from '../../models/post.model';
import CameraIcon from '../../assets/images/svg/camera.svg';

import Title from '../atoms/title';
import Subtitle from '../atoms/subtitle';
import Markdown from '../hoc/markdown';
import Author from '../molecules/author';
import Section from '../hoc/section';
import TagChipList from '../molecules/tag-chip-list';
import createImageHolder from '../../utils/create-image-holder';

export interface IPostSection extends ISection {
    extendedItems: {
        post: IPost;
    };
}

interface IPostHeaderProps {
    className?: string;
    section: IPostSection;
    TitleTag?: React.ElementType;
}

const Post: React.FC<IPostHeaderProps> = ({ className = '', section, TitleTag = 'h2' }) => {
    const { post } = section.extendedItems;

    return (
        <Section
            className={`${className} ${sectionBox}`}
            style={section.style}
            classes={{ container }}
            roundedSection={true}
            sectionId={section.sectionId}
            css={section.css}
        >
            <Subtitle className={categories}>
                {post.mainCategory && (
                    <Link className={categoryLink} to={post.mainCategory.pathname}>
                        {post.mainCategory.title}
                    </Link>
                )}
            </Subtitle>
            <Title className={title} Tag={TitleTag}>
                {post.title}
            </Title>
            <time className={date}>{post.displayPublishedAt}</time>
            <div className={leadImage}>
                <Image
                    className={desktopLeadImage}
                    media={post.media}
                    ratioClass={desktopLeadImageRatio}
                    relation={relations.mainImage}
                    showCaption
                    renderCaption={renderCaption}
                />
                <Image
                    className={mobileLeadImage}
                    media={post.media}
                    ratioClass={mobileLeadImageRatio}
                    relation={relations.mainImageMobile}
                    showCaption
                    renderCaption={renderCaption}
                />
            </div>
            <div className={lead}>
                <strong className={leadText}>{post.lead}</strong>
            </div>
            <div className={markdownBox}>
                {post.content && (
                    <Markdown
                        className={markdown}
                        components={{
                            p: ({ children, node }) => {
                                return createImageHolder({
                                    children,
                                    nodeChildren: node.children[0],
                                    Tag: node.tagName,
                                    className: imageHolder,
                                });
                            },
                            figure: ({ children, node }) => {
                                return createImageHolder({
                                    children,
                                    nodeChildren: node.children[0],
                                    Tag: node.tagName,
                                    className: imageHolder,
                                });
                            },
                            figcaption: ({ children }) => renderCaption(children),
                        }}
                    >
                        {post.content}
                    </Markdown>
                )}
            </div>
            {post.tags && post.tags.length > 0 && <TagChipList className={tags} tags={post.tags} />}
            {post.author && <Author className={author} author={post.author} NameTag="h4" />}
        </Section>
    );
};

function renderCaption(caption: React.ReactNode) {
    return (
        <figcaption className={captionClass}>
            <CameraIcon /> {caption}
        </figcaption>
    );
}

export default Post;
